import React, { Component } from "react";
import NewsletterService from "../services/newsletter.service";
import Logo from "../assets/img/timea_logo.png";
import Portrait from "../assets/img/timea_portrait.jpg";
import './Home.css';

const regexEmail = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;

class Home extends Component {

    state = {
        email: "",
        // ok : vérifier si le formulaire est correctement rempli
        ok: false,
        // messageFail : erreur à afficher à l'utilisateur
        messageFail: false,
        // messageSuccess : confirmation à afficher à l'utilisateur
        messageSuccess: false
    }

    handleChange(e) {
        regexEmail.test(e.target.value) ? this.setState({ ok: true }) : this.setState({ ok: false });
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    async submit(e) {
        e.preventDefault();
        // Si formulaire rempli correctement
        if (this.state.ok) {
            let response = await NewsletterService.create(this.state);
            // Si requête réussie
            if (response.ok) {
                this.setState({ messageSuccess: "Vous avez bien été enregistré." })
            } else {
                // Erreur validation API
                let data = await response.json();
                this.setState({ messageFail: data.message })
            }
        } else {
            this.setState({ messageFail: "Votre adresse mail est incorrecte." })
        }
    }


    render() {
        return (
            <div>
                <div className="container main-content mt-5 text-center">
                    <img src={Portrait} className="img-fluid rounded mx-auto mt-3 timea-portrait" alt={"Timéa"} />
                    <img src={Logo} className="mt-4 d-block mx-auto timea-logo" alt={"Timéa music"} />
                    <p className="main-text mt-5 w-50 mx-auto">Pour savoir quelle surprise arrive au mois de mai, inscris-toi juste en dessous
                <span className="additional-wording">💙</span></p>
                    {
                        // Gestion des messages à afficher à l'utilisateur
                        this.state.messageFail ?
                            <div className="alert alert-danger fade show w-75 mx-auto" role="alert">
                                {this.state.messageFail}
                            </div>
                            : this.state.messageSuccess ?
                                <div className="alert alert-success fade show w-75 mx-auto" role="alert">
                                    {this.state.messageSuccess}
                                </div>
                                : null
                    }
                    <form onSubmit={(e) => this.submit(e)}>
                        <div className="form-group">
                            <label htmlFor="emailInput" className="formInputLabel">Adresse email</label>
                            <input type="email" className="form-control form-input mx-auto" id="email" placeholder="Email" onChange={(e) => this.handleChange(e)} required />
                        </div>
                        <button type="submit" className="btn w-50 btn-primary mt-3">S'abonner</button>
                    </form>
                    <div className="socials mt-4">
                        {/* Instagram */}
                        <a href="https://www.instagram.com/timeamusic/" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                            </svg>
                        </a>
                        {/* Facebook */}
                        <a href="https://www.facebook.com/timeamusicc/" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                            </svg>
                        </a>
                        {/* YouTube */}
                        <a href="https://www.youtube.com/channel/UC1XW-2iR05_k91NpZZvKGgA" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                            </svg>
                        </a>
                        {/* SoundCloud */}
                        <a href="https://soundcloud.com/timeamusic" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M0 0v24h24v-24h-24zm4.667 15.524c-.405-.365-.667-.903-.667-1.512 0-.608.262-1.146.667-1.512v3.024zm1.333.476c-.243 0-.369.003-.667-.092v-3.792c.316-.101.465-.097.667-.081v3.965zm1.333 0h-.666v-3.778l.206.121c.091-.375.253-.718.461-1.023v4.68zm1.334 0h-.667v-5.378c.206-.154.426-.286.667-.377v5.755zm1.333 0h-.667v-5.905c.251-.027.328-.046.667.006v5.899zm1.333 0h-.667v-5.7l.253.123c.119-.207.261-.395.414-.572v6.149zm6.727 0h-6.06v-6.748c.532-.366 1.16-.585 1.841-.585 1.809 0 3.275 1.494 3.411 3.386 1.302-.638 2.748.387 2.748 1.876 0 1.143-.869 2.071-1.94 2.071z" />
                            </svg>
                        </a>
                    </div>
                    <div className="contact mt-3">
                        Contact : <a href="mailto:timea@timeamusic.com">timea@timeamusic.com</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;
