const baseUrl = "https://api-timea.herokuapp.com";

class NewsletterService {
    static async create(body) {
        let init = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
        let call = await fetch(`${baseUrl}/newsletter/new`, init);
        return call;
    }
}

export default NewsletterService;